*, *:before, *:after { box-sizing: border-box; }
*:focus { outline: none; box-shadow: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  z-index: 1;
  display: block;
  margin: 0; padding: 0; border: 0;
  background-color: #eee;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, /*'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/ sans-serif;
  font-size: 12px;
  color: #2a2a2a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  margin: 0; padding: 0; border: 0;
  font-size: 1em;
  flex: 0 0 auto;
  white-space: pre-wrap;
}

table { border-collapse: collapse; }
table, td {
  margin: 0; padding: 0;
  border: solid 1px #666;
  font-size: 1em;
}

input {
  margin: 0; padding: 0.18em 0.28em;
  outline: none;
  border: solid 1px #aaa;
  font-family: sans-serif; font-size: 1em;
}

textarea {
  margin: 0; padding: 0.3em 0.4em;
  border-style: solid; border-width: 1px; border-color: #bbb #aaa #aaa #bbb;
  font-family: sans-serif; font-size: 1em;
  width: 100%; max-width: 100%;
  min-height: 2.2em; height: 100%;
  resize: none;
}

select {
  margin: 0; padding: 0;
  font-size: 1em;
}

img {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  transform: translateZ(0);
  -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
  -ms-transform: translateZ(0); /* IE 9 */

  image-rendering: pixelated;

  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast; /* Edge */
  image-rendering: -moz-crisp-edges;          /* Firefox */
  image-rendering: -o-crisp-edges;            /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  -ms-interpolation-mode: nearest-neighbor;   /* IE (non-standard property) */
}




/* flex box */
.displayFlexRow {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
}
.displayFlexRowVc {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
  align-items: center;
}
.displayFlexRowHcVc {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
  align-items: center;
  justify-content: center;
}
.displayFlexRowHcWrap {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
  justify-content: center;
  flex-wrap: wrap;
}

.displayFlexColumn {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
  flex-direction: column;
}
.displayFlexColumnHc {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
  flex-direction: column;
  align-items: center;
}
.displayFlexColumnVc {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
  flex-direction: column;
  justify-content: center;
}
.displayFlexColumnHcVc {
  display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
  min-width: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex11a { flex: 1 1 auto; min-width: 0; min-height: 0; } /* item grows to fill space */
.flex00a { flex: 0 0 auto; min-width: 0; min-height: 0; } /* set style={{flexBasis:"10em"}}, item will be 10em no grow or shrink */
.flex01a { flex: 0 1 auto; min-width: 0; min-height: 0; } /* set style={{flexBasis:"10em"}}, item will shrink if compressed, but will not grow to fit a large space (topBar search captures input) */
.flex10a { flex: 1 0 auto; min-width: 0; height: 0; } /* set style={{flexBasis:"10em"}}, item will grow, but will not shrink past a certain point (capture cards vertical height) [height: 0 helps Edge browser not expand height for longer data] */

.flexWrap { flex-wrap: wrap; }



/* position/display */
.positionFixed { position: fixed; }
.positionRelative { position: relative; }
.positionAbsolute { position: absolute; }
.t0 { top: 0; }
.r0 { right: 0; }
.b0 { bottom: 0; }
.l0 { left: 0; }
.positionFixedFullScreen { position: fixed; top: 0; right: 0; bottom: 0; left: 0; }

.inlineBlock { display: inline-block; } /* error in IE with flex column alignitems center, so for horizontal centering, use inline block */
.overflowHidden { overflow: hidden; }
.width100 { width: 100%; }



/* scroll */
.xScrollAlways { overflow-x: scroll; overflow-y: hidden; }
.xScrollHidden { overflow-x: auto; overflow-y: hidden; } .xScrollHidden::-webkit-scrollbar { display: none; }
.yScroll { overflow-x: hidden; overflow-y: auto; }
.yScrollAlways { overflow-y: scroll; }
.yScrollHidden { overflow-x: hidden; overflow-y: auto; -ms-overflow-style: none; scrollbar-width: none; } .yScrollHidden::-webkit-scrollbar { display: none; }
.xyScroll { overflow: auto; }
.xyScrollAlways { overflow-x: scroll; overflow-y: scroll; }



/* borders */
.border1 { border: solid 1px; }



/* text */
.textLeft { text-align: left; }
.textCenter { text-align: center; }
.textRight { text-align: right; }

.textOverflowEllipsis { text-overflow: ellipsis; }

.verticalUpText { transform: rotate(270deg); transform-origin: 50% 50%; }
.verticalDownText { transform: rotate(90deg); transform-origin: 50% 50%; }

.nowrap { white-space: nowrap; }
.nowrapOuter { display: table; width: 100%; }
.nowrapMiddle { display: table-cell; width: 100%; }
.nowrapInner {
  width: 0;
  min-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.maxHeightWrap { overflow: hidden; word-break: break-word; word-wrap: break-word; overflow-wrap: break-word; }



/* cursors */
.pointerEventsNone { pointer-events: none; } /* allows passthrough of pointer events through an overlaying absolutely positioned child element to access a parents mouse pointer events (hover, click, title, etc) */

.cursorPointer { cursor: pointer; }
.cursorGrab { cursor: grab; }
.cursorLRResizeArrows { cursor: ew-resize; }
.cursorNotAllowed { cursor: not-allowed; }
.cursorHelp { cursor: help; }

.noSelect { user-select: none; }



/* font */
.font08 { font-size: 0.8em; } .font09 { font-size: 0.9em; }
.font11 { font-size: 1.1em; } .font12 { font-size: 1.2em; } .font13 { font-size: 1.3em; } .font14 { font-size: 1.4em; } .font15 { font-size: 1.5em; }
.font16 { font-size: 1.6em; } .font17 { font-size: 1.7em; } .font18 { font-size: 1.8em; } .font19 { font-size: 1.9em; } .font20 { font-size: 2em; }
.font21 { font-size: 2.1em; } .font22 { font-size: 2.2em; } .font23 { font-size: 2.3em; } .font24 { font-size: 2.4em; } .font25 { font-size: 2.5em; }
.font30 { font-size: 3.0em; } .font35 { font-size: 3.5em; }

.fontBold { font-weight: bold; }
.fontItalic { font-style: italic; }
.fontUnderline { text-decoration: underline; }
.fontUnderlineDotted { text-decoration: underline; text-decoration-style: dotted; }



/* colors/hover */
.hoverLighterBlueGradient:hover { background: #edf3fc; background: linear-gradient(#eef5fd, #c6ddf3); }
.hoverLighterBlueGradient:active { background: #e6eef9; background: linear-gradient(#eef6fc, #dfe6f7); }



/* inputs */
.buttonNowrap { display: inline-block; text-align: center; white-space: nowrap; }
.inputTealBoxShadowFocus:focus { box-shadow: 0 0 0.1em 0.1em #005da3; outline: none; }
.inputRedBorderError { outline: solid 1px #e33 !important; }

.selectAddNewEntryButton { padding: 0.15em 1em; background: #ddd; font-style: italic; color: #333; cursor: pointer; border: solid 1px #ccc; border-color: #ddd #bbb #bbb #ddd; border-radius: 0.3em }
.selectAddNewEntryButton:hover { background: #f1f1f6; }
.selectAddNewEntrySaveButton { padding: 0.15em 1em; background: #abf; font-style: italic; color: #333; cursor: pointer; border: solid 1px #ccc; border-color: #ddd #bbb #bbb #ddd; border-radius: 0.3em }
.selectAddNewEntrySaveButton:hover { background: #cdf; }
.selectAddNewEntryCancelButton { padding: 0.15em 1em; background: #ddd; font-style: italic; color: #333; cursor: pointer; border: solid 1px #ccc; border-color: #ddd #bbb #bbb #ddd; border-radius: 0.3em }
.selectAddNewEntryCancelButton:hover { background: #f1f1f6; }
