/* Jim/Ryan CaptureExec colors: (dark blue) 18305a, (blue) 005da3, (red) bd2326, (dark gray) 58595b, (gray) bcbec0*/
.fontTextLight { color: #555658; }
.fontTextLighter { color: #6b6b6b; }
.fontTextLightest { color: #888; }
.fontTextLightester { color: #aaa; }
.fontTextLightestest { color: #ccc; }
.fontAlmostWhite { color: #eee; }
.fontWhite { color: #fff; }
.fontDarkBlue { color: #18305a; }
.fontBlue { color: #005da3; }
.fontBlueLight { color: #4a8bbd; }
.fontBlueLighter { color: #79a5c7; }
.fontBlueLightest { color: #b2d2f2; }
.fontDarkerRed { color: #880000; }
.fontDarkRed { color: #aa4444; }
.fontRed { color: #bd2326; }
.fontLightRed { color: #d88; }
.fontRedCapture { color: #996066; }
.fontDarkGreen { color: #373; }
.fontGreen { color: #00a35d; }
.fontLightGreen { color: #8d8; }
.fontPurple { color: #647; }
.fontPurpleLighter { color: #cad; }
.fontLightBrown { color: #b97; }
.fontOrange { color: #fa7; }
.fontDarkOrange { color: #886644; }
.fontGold { color: #887e44; }
.fontTeal { color: #089; }

.hoverFontUnderline:hover { text-decoration: underline; }

.bgBlack { background: #000000; }
.bgDarkestGray { background: #333333; }
.bgDarkerGray { background: #555555; }
.bgDarkGray { background: #666666; }
.bgGray, .hoverGray:hover { background: #999999; }
.bgLightGray, .hoverLightGray:hover { background: #cccccc; }
.bgLighterGray, .hoverLighterGray:hover { background: #eeeeee; }
.bgLightestGray, .hoverLightestGray:hover { background: #f5f5f5; }
.bgLightesterGray, .hoverLightesterGray:hover { background: #fafafa; }
.bgLightestestGray, .hoverLightestestGray:hover { background: #fdfdfd; }
.bgAlmostWhite { background: #fefefe; }
.bgWhite, .hoverBgWhite:hover { background: #fff; }
.bgDarkGrayGradient { background: #666666; background: linear-gradient(#999999, #666666); }
.bgGrayGradient, .hoverGrayGradient:hover { background: #999999; background: linear-gradient(#cccccc, #999999); }
.bgLightGrayGradient, .hoverLightGrayGradient:hover { background: #cccccc; background: linear-gradient(#eeeeee, #cccccc); }
.bgLighterGrayGradient, .hoverLighterGrayGradient:hover { background: #f2f2f2; background: linear-gradient(#f5f5f5, #eeeeee); }
.hoverLightestGrayGradient:hover { background: #fafafa; background: linear-gradient(#fefefe, #f9f9f9); } .hoverLightestGrayGradient:active { background: #ffffff; }
.bgLightesterGrayGradient { background: #fafafa; background: linear-gradient(#ffffff, #f0f0f0); }

.bgDarkBlue { background: #18305a; }
.bgBlue { background: #005da3; }
.bgLightBlue, .hoverLightBlue:hover { background: #4a8bbd; }
.bgLighterBlue, .hoverLighterBlue:hover { background: #e6ebfa; }
.bgLightestBlue, .hoverLightestBlue:hover { background: #f5fbff; }

.bgDarkerBlueGradient { background: #18305a; background: linear-gradient(#337, #18305a); }
.bgDarkBlueGradient { background: #18305a; background: linear-gradient(#005da3, #18305a); }
.bgBlueGradient, .hoverBlueGradient:hover { background: #005da3; background: linear-gradient(#4a8bbd, #005da3); }
.bgBlueGradientHover:hover { background: #79a5c7; background: linear-gradient(#79a5c7, #4a8bbd); } .bgBlueGradientHover:active { background: #8bd; background: linear-gradient(#8bd, #79a5c7); }
.bgLightBlueGradient, .hoverLightBlueGradient:hover { background: #4a8bbd; background: linear-gradient(#e6ebfa, #4a8bbd); }
.bgLighterBlueGradient { background: #edf3fc; background: linear-gradient(#f5fbff, #e6ebfa); }
.hoverLighterBlueGradient:hover { background: #edf3fc; background: linear-gradient(#eef5fd, #c6ddf3); } .hoverLighterBlueGradient:active { background: #e6eef9; background: linear-gradient(#eef6fc, #dfe6f7); }
.hoverLightestBlueGradient:hover { background: #f8fdff; background: linear-gradient(#fff, #f5fbff); }

.bgDarkGreen { background: #185a30; }
.bgMidDarkGreen { background: #2e834d; }
.bgGreen, .hoverGreen:hover { background: #4abd8b; }
.bgLightGreen, .hoverLightGreen:hover { background: #e6faeb; }
.bgLighterGreen, .hoverLighterGreen:hover { background: #edf1ed; }
.bgDarkerGreenGradient { background: #185a30; background: linear-gradient(#185a30, #030); }
.bgDarkGreenGradient, .hoverDarkGreenGradient:hover { background: #185a30; background: linear-gradient(#00a35d, #185a30); }
.bgGreenGradient { background: #00a35d; background: linear-gradient(#4abd8b, #00a35d); }
.hoverGreenGradient:hover { background: #00a35d; background: linear-gradient(#4abd8b, #00a35d); }
.bgLightGreenGradient { background: #4abd8b; background: linear-gradient(#e6faeb, #4abd8b); }
.hoverLightGreenGradient:hover { background: #4abd8b; background: linear-gradient(#e6faeb, #4abd8b); }
.bgLighterGreenGradient { background: #e6f4eb; background: linear-gradient(#edf4f2, #e6f4eb); }

.bgRed { background: #bd2326; }
.hoverRed:hover { background: #bd2326; color: #fff; } .hoverRed:active { background: #dd4346; color: #fff; }
.bgLightRed { background: #fdd; }
.bgDarkRedGradient { background: linear-gradient(#c00,#800); }
.bgRedGradient { background: linear-gradient(#ebb,#c88); }
.bgLightRedGradient { background: linear-gradient(#fdd,#daa); }
.hoverLighterRedGradient:hover { background: linear-gradient(#fee,#ebb); }

.bgYellow { background: #ee5; }
.bgLightYellow { background: #ff9; }
.bgGoldGradient { background: linear-gradient(#eeb,#cc8); }
.bgLightGoldGradient { background: linear-gradient(#ffd,#dda); }

.bgLightOrange { background: #fdb; }
.bgLighterOrange { background: #fed; }
.bgDarkOrangeGradient { background: linear-gradient(#c96,#963); }
.bgOrangeGradient { background: linear-gradient(#fdb,#d95); }
.bgLightOrangeGradient { background: linear-gradient(#fdb,#fc9); }
.hoverLightOrangeGradient:hover { background: linear-gradient(#fdb,#fc9); }
.bgLighterOrangeGradient { background: linear-gradient(#fed,#fdb); }
.hoverLighterOrangeGradient:hover { background: linear-gradient(#fed,#fdb); }

.bgLightPurple { background: #dcd; }
.bgDarkPurpleGradient { background: #647; background: linear-gradient(#758, #536); }
.bgPurpleGradient { background: #869; background: linear-gradient(#97a, #758); }
.bgLightPurpleGradient { background: #b9c; background: linear-gradient(#dbe, #a8b); }
.hoverLightPurpleGradient:hover { background: #b9c; background: linear-gradient(#dbe, #a8b); }
.hoverLighterPurpleGradient:hover { background: #dbe; background: linear-gradient(#ecf, #dbe); }

.bgTealGradient { background: #00a3ad; background: linear-gradient(#4abdbb, #00a3ad); }

.bgLighterBrown { background: #efdcc9; }



.border { border: solid 1px; }
.border1transparent { border: solid 1px transparent; }
.border2 { border: solid 2px; }
.border2transparent { border: solid 2px transparent; }
.borderTB { border-top: solid 1px; border-bottom: solid 1px; }
.border1eee { border: solid 1px #eee; }
.borderT1eee { border-top: solid 1px #eee; }
.border1ddd { border: solid 1px #ddd; }
.borderT1ddd { border-top: solid 1px #ddd; }
.borderR1ddd { border-right: solid 1px #ddd; }
.borderB1ddd { border-bottom: solid 1px #ddd; }
.borderL1ddd { border-left: solid 1px #ddd; }
.border1bbb { border: solid 1px #bbb; }
.borderT1bbb { border-top: solid 1px #bbb; }
.borderR1bbb { border-right: solid 1px #bbb; }
.borderB1bbb { border-bottom: solid 1px #bbb; }
.borderL1bbb { border-left: solid 1px #bbb; }
.border1444 { border: solid 1px #444; }
.borderT1Blue { border-top: solid 1px #005da3; }
.border1LightRed { border: solid 1px #ebb; }
.border1LightGreen { border: solid 1px #bcb; }
.border2999 { border: solid 2px #999; }
.borderRadius05 { border-radius: 0.5em; }
.borderRadius10 { border-radius: 1em; }
.borderRadius15 { border-radius: 1.5em; }
.bevelBorderLightColors { border-color: #ccc #aaa #aaa #ccc !important; }
.bevelBorderColors { border-color: #f6f6f6 #999 #999 #f6f6f6 !important; }
.bevelBorderDarkColors { border-color: #7f7f7f #4a4a4a #4a4a4a #7f7f7f !important; }
.bevelBorderColorBlue { border-color: #abe #005da3 #005da3 #abe; }
.borderColorRed { border-color: #bd2326; }
.borderColorDarkRed { border-color: #aa4444; }
.borderColorDarkGreen { border-color: #373; }

.hoverBorderColorGray:hover { border-color: #999999; }
.hoverBorderColorLightGray:hover { border-color: #cccccc; }
.hoverBorderColorLighterGray:hover { border-color: #eeeeee; }
.hoverBorderColorBlue:hover { border-color: #005da3; }


.boxShadowDarkGrayDarkBlueHover { box-shadow: 0.6em 0.6em 0.6em #666; }
.boxShadowDarkGrayDarkBlueHover:hover { box-shadow: 0.6em 0.6em 0.6em #579; }
.boxShadowGreenDarkGreenHover { box-shadow: 0.6em 0.6em 0.6em #4abd8b; }
.boxShadowGreenDarkGreenHover:hover { box-shadow: 0.6em 0.6em 0.6em #00a35d; }
.boxShadowDarkGray { box-shadow: -0.6em 0.6em 0.6em #666; }
.boxShadowThinFullOutlineBlue { box-shadow: 0 0em 0.2em 0.2em #005da3; }



/*SGT palette: [#394853 #ffffff] [#00aaf4 #006ec1 #0054ad] [#e93c39 #b10024]*/
.fontDarkGrayBlueSamGovTracker { color: #394853; }
.fontDarkRedSamGovTracker { color: #b10024; }
.bgDarkGrayBlueSamGovTracker { background: #394853; }
.bgRedSamGovTracker { background: #e93c39; }
.bgDarkRedSamGovTracker { background: #b10024; }
.bgRedGradientSamGovTracker { background: #b10024; background: linear-gradient(#e93c39, #b10024); }
.hoverRedSamGovTracker:hover { background: #d31b33; }
.hoverLightRedGradientSamGovTracker:hover { background: #c11034; background: linear-gradient(#f94c49, #c11034); }
.border1DarkRedSamGovTracker { border: solid 1px #b10024; }
.sgtAdSection1BgBlueGradient { background: #00aaf4; background: linear-gradient(#1c57a8, #3d9ae0); }
.sgtAdSection2BgSkyBlueGradient { background: #00aaf4; background: linear-gradient(#dbe4f0, #bad6ec); }
.sgtAdSection3BgBlue { background: #1c57a8; }


.bgCaptureExec { background: #66666f; }


.bgTopBar { background: #ebebeb; background: linear-gradient(#f3f3f3, #e4e4e4); }
.bgTopBarSamGovTracker { background: #d0d0d0; }

.bgLeftNavAndTabs { background: #717171; }
.hoverLeftNavTab:hover { background: #909090; background: linear-gradient(#909090, #808080); } .hoverLeftNavTab:active { background: #aaa; }
.bgLeftNavTabSelected { background: #333333; }
.bgLeftNavSubTab { background: #777771; }
.hoverLeftNavSubTab:hover { background: #969690; background: linear-gradient(#969690, #868680); } .hoverLeftNavSubTab:active { background: #afafaa; }
.bgLeftNavSubTabSelected { background: #393933; }

.bgLeftNavAndTabsSamGovTracker { background: #d0d0d0; }
.hoverLeftNavTabSamGovTracker:hover { background: #fafafa; background: linear-gradient(#fefefe, #f9f9f9); } .hoverLeftNavTabSamGovTracker:active { background: #ffffff; }
.bgLeftNavTabSamGovTrackerSelected { background: #aaaaaa; }
.bgLeftNavSubTabSamGovTracker { background: #d6d6d0; }
.hoverLeftNavSubTabSamGovTracker:hover { background: #e6e6e0; background: linear-gradient(#fffffe, #fbfbf9); } .hoverLeftNavSubTabSamGovTracker:active { background: #ffffff; }
.bgLeftNavSubTabSamGovTrackerSelected { background: #b2b2aa; }

.bgPanelLightGray { background: #f0f0f0; }

.bgMasterButtonSelectedBgSamGovTracker { background: #bbb; background: linear-gradient(#eef5fd, #c6ddf3); }

.bgCstColumnHeaderRowBg { background: #005da3; background: linear-gradient(#4a8bbd, #005da3); }
.bgCstColumnHeader { background: #18305a; background: linear-gradient(#005da3, #18305a); }
.bgCstColumnHeaderSortedPrimary { background: #4a8bbd; background: linear-gradient(#e6ebfa, #4a8bbd); }
.bgCstColumnHeaderSortedSecondary { background: #4a8bbd; background: linear-gradient(#e6ebfa, #4a8bbd); }
.bgCstColumnHeaderRowBgSamGovTracker { background: #0054ad; }
.bgCstColumnHeaderSamGovTracker { background: #006ec1; }
.bgCstColumnHeaderSortedPrimarySamGovTracker { background: #00aaf4; }
.bgCstColumnHeaderSortedSecondarySamGovTracker { background: #00aaf4; }

.bgCstPresetsMaster { background: #cccccc; background: linear-gradient(#eeeeee, #cccccc); }
.bgCstPresetsCstColumns { background: #18305a; background: linear-gradient(#005da3, #18305a); }
.bgCstPresetsFilters { background: #005da3; background: linear-gradient(#4a8bbd, #005da3); }
.bgCstPresetsSorts { background: #4a8bbd; background: linear-gradient(#e6ebfa, #4a8bbd); }

.bgCardGrayBg { background: #f1f1f1; }

.bgDealShapingQuestionTileLightGrayBg { background: #f9f9fa; }

.bgTeammatesUs { background: #005da3; }
.bgTeammatesLargeBusiness { background: #884488; }
.bgTeammatesSmallBusiness { background: #229999; }
.bgTeammatesInvalidBusinessType { background: #333333; }
.bgTeammatesPrimeCompany { background: #886644; }
.bgTeammatesSubtab { background: #cccccc; background: linear-gradient(#eeeeee,#cccccc); }
.bgTeammatesSubtabSelected { background: #4a8bbd; background: linear-gradient(#e6ebfa,#4a8bbd); }
.bgTeammatesTeammateBgExpanded { background: #f9f9fa; }
.bgTeammatesTeammateBgCollapsed { background: #ddddde; }

.bgTeammateContractsProcessSubtab { background: #cccccc; background: linear-gradient(#eeeeee,#cccccc); }
.bgTeammateContractsProcessSubtabSelected { background: #4a8bbd; background: linear-gradient(#e6ebfa,#4a8bbd); }

.bgConversationsLightestBlueGradientLR { background: #f0f4fc; background: linear-gradient(90deg,#f0f4fc,#f6f9ff); }
.bgConversationsLightestBlue { background: #f0f4fc; }

.bgRedCapture { background: #885055; background: linear-gradient(#996066, #885055); } /*a32f31*/
.hoverRedCapture:hover { background: #aa7077; }

.bgContactsLightestYellow { background: #fffff6; }
.bgContactsLighterYellow { background: #fcfcdc; }
.bgContactsLightYellow { background: #f5f5a2; }
.bgContactsYellow { background: #f1f1c1; }
.bgContactsDarkYellow { background: #997; }
.bgContactsPersonCompanyIcon { background: #996; }
.bgContactsAddNewButtonWithHover { background: #aa5; background: linear-gradient(#aa5, #995); } .bgContactsAddNewButtonWithHover:hover { background: #cc7; background: linear-gradient(#cc7, #bb7); }

.bgUserTan { background: #f5f2f0; }

.bgAddEditDeleteTable {background: #c7c1bb; }
.bgAdminCaptureTypeTable {background: #dde3fc; }
.bgAdminCaptureTypeTableReceiving {background: #99d6f0; }
.bgAdminCaptureTypeTableDrop {background: #cef; }




.bgPanelSwitchLoginTab { background: #d6d6d8; }
.bgMobileLeftNavLauncherOpen { background: linear-gradient(#f3f3f3, #bbb); }
.bgUserPanelLauncherOpen { background: #dde; background: linear-gradient(#f3f3f3, #bdcbe1); }
.bgTasksPanelLauncherOpen { background: #ded; background: linear-gradient(#f3f3f3, #bdb); }
.bgNotificationsPanelLauncherOpen { background: #eed; background: linear-gradient(#f3f3f3, #ddb); }
.bgHelpPanelLauncherOpen { background: #edd; background: linear-gradient(#f3f3f3, #daa); }
.bgMultiLoginSelected { background: #dde; background: linear-gradient(#eef, #bdcbe1); }
.bgCaptureItemEditing { background: #ecf5fd; background: linear-gradient(#ecf5fd, #bfe3ff); }


/* ======================================================================================================= */
/* spacing */
.lrMargin { margin-left: 0.4em; margin-right: 0.4em; }
.rMargin { margin-right: 0.4em; }
.lrMedMargin { margin-left: 0.8em; margin-right: 0.8em; }
.rMedMargin { margin-right: 0.8em; }
.lrBigMargin { margin-left: 1em; margin-right: 1em; }
.lrHugeMargin { margin-left: 2em; margin-right: 2em; }
.lrMicroPad { padding-left: 0.15em; padding-right: 0.15em; }
.lrPad { padding-left: 0.4em; padding-right: 0.4em; }
.lrMedPad { padding-left: 0.8em; padding-right: 0.8em; }
.lrBigPad { padding-left: 1em; padding-right: 1em; }
.lrHugePad { padding-left: 2em; padding-right: 2em; }

.tbMicroMargin { margin-top: 0.15em; margin-bottom: 0.15em; }
.tbMargin { margin-top: 0.4em; margin-bottom: 0.4em; }
.tbMedMargin { margin-top: 0.8em; margin-bottom: 0.8em; }
.tbBigMargin { margin-top: 1em; margin-bottom: 1em; }
.tbHugeMargin { margin-top: 2em; margin-bottom: 2em; }
.tbNanoPad { padding-top: 0.05em; padding-bottom: 0.05em; }
.tbMicroPad { padding-top: 0.15em; padding-bottom: 0.15em; }
.tbPad { padding-top: 0.4em; padding-bottom: 0.4em; }
.tbMedPad { padding-top: 0.8em; padding-bottom: 0.8em; }
.tbBigPad { padding-top: 1em; padding-bottom: 1em; }
.tbHugePad { padding-top: 2em; padding-bottom: 2em; }

.microTopMargin { margin-top: 0.15em; }
.microBottomMargin { margin-bottom: 0.15em; }
.microFullMargin { margin: 0.15em; }
.microFullPad { padding: 0.15em; }
.microTopPad { padding-top: 0.15em; }
.microBottomPad { padding-bottom: 0.15em; }

.smallTopMargin { margin-top: 0.4em; }
.smallBottomMargin { margin-bottom: 0.4em; }
.smallFullMargin { margin: 0.4em; }
.smallFullPad { padding: 0.4em; }
.smallTopPad { padding-top: 0.4em; }
.smallBottomPad { padding-bottom: 0.4em; }

.medTopMargin { margin-top: 0.8em; }
.medBottomMargin { margin-bottom: 0.8em; }
.medFullMargin { margin: 0.8em; }
.medTopPad { padding-top: 0.8em; }
.medBottomPad { padding-bottom: 0.8em; }
.medFullPad { padding: 0.8em; }

.bigTopMargin { margin-top: 1em; }
.bigBottomMargin { margin-bottom: 1em; }
.bigTopPad { padding-top: 1em; }
.bigBottomPad { padding-bottom: 1em; }
.bigFullPad { padding: 1em; }

.hugeTopMargin { margin-top: 2em; }
.hugeBottomMargin { margin-bottom: 2em; }
.hugeFullMargin { margin: 2em; }
.hugeTopPad { padding-top: 2em; }
.hugeBottomPad { padding-bottom: 2em; }
.hugeFullPad { padding: 2em; }

.massiveTopMargin { margin-top: 3em; }
.enormousTopMargin { margin-top: 5em; }

.yScrollBottomPad { padding-bottom: 1.5em; }






/* ======================================================================================================= */
/* nowrap */
.breakWord { word-break: break-word; word-wrap: break-word; overflow-wrap: break-word; }


/* ======================================================================================================= */
/* errors/messages/loading */
.messageBorder { border: solid 1px #dddddd; }
.message_userError { background: #faccd0; background: linear-gradient(#fcd9da, #faccd0); }
.message_systemError { background: #eabcc0; background: linear-gradient(#ecc9ca, #eabcc0); }
.message_success { background: #ccfad0; background: linear-gradient(#d9fcda, #ccfad0); }
.message_loginUserError { background: #a55; background: linear-gradient(#844, #a55); border: solid 1px #444; color: #eee; }
.message_loginSystemError { background: #a33; background: linear-gradient(#833, #a33); border: solid 1px #444; color: #eee; }
.message_loginSuccess { background: #252; background: linear-gradient(#252, #373); border: solid 1px #444; color: #eee; }

.captureExecErrorMessage {
  border: solid 1px #bbb;
  padding: 1.5em 2em;
  white-space: pre-wrap;
  cursor: pointer;
}

/* ======================================================================================================= */
/* login multiLogin adminPassword/changePassword */
.ceLoginInput {
  width: 100%;
  height: 2.7em;
  padding: 0.75em;
  border-radius: 0.2em;
  border: solid 2px #aaa;
  border-top-color: #eee;
  border-left-color: #eee;
  background: #ddd;
  color: #444;
}


.sgtLoginInput {
  width: 100%;
  height: 2.7em;
  padding: 0.75em;
  border-radius: 0.2em;
  border: solid 2px #aaa;
  border-top-color: #eee;
  border-left-color: #eee;
  background: #fafafa;
  color: #444;
}


/* ======================================================================================================= */
/* topBar */
.searchCapturesShadow {
  box-shadow: 0.3em 0.3em 0.4em #18305a;
}
.topBarPanelLauncherLeftBorder { border-left: solid 1px #c0c3c8; }



/* ======================================================================================================= */
/* leftNav animation */
.leftNavMobile {
  z-index: 6;
  animation-name: leftNavSlideAnimation; animation-duration: 0.15s; animation-timing-function: ease-out;
}
@keyframes leftNavSlideAnimation {
  from { width: 20vw; }
  to { width: 90vw; }
}





/* ======================================================================================================= */
/* Open Capture */
.singleCaptureOverlayWithAnimation {
  animation-name: openCaptureSlideAnimation; animation-duration: 0.15s; animation-timing-function: ease-out;
}
@keyframes openCaptureSlideAnimation {
  from {left: 60vw;}
  to {left: 0; }
}


.cardLaunchButtonBgInactive { background: #f4f4f4; }
.cardLaunchButtonBgActive { background: #ddf4ff; background: linear-gradient(#e6faff, #daf1ff); }
.cardLaunchButtonBgActive:hover { background: #e8fbff; }





/* ======================================================================================================= */
/* Right Panel */
.rightPanelAnimationMobile {
  animation-name: rightPanelSlideAnimationMobile; animation-duration: 0.15s; animation-timing-function: ease-out;
}
@keyframes rightPanelSlideAnimationMobile {
  from {left: 90vw;}
  to {left: 10vw; }
}


.ticketPriorityCell { flex: 0 0 2em; display: flex; flex-direction: column; justify-content: center; padding-left: 0.3em; }
.bgPriority1 { background: #fff; background: linear-gradient(90deg, #fff, transparent); }
.bgPriority2 { background: #fba; background: linear-gradient(90deg, #fba, transparent); }
.bgPriority3 { background: #e9e988; background: linear-gradient(90deg, #e9e988, transparent); }
.bgPriority4 { background: #9ea; background: linear-gradient(90deg, #9ea, transparent); }
.bgTicketOpen { background: #fbfbfb; }
.bgTicketClosed { background: #f5f5f5; }


.userPanelTicketsTicketResponse {
  margin-left: 2em;
  border: solid 1px #d1d1d1;
  border-radius: 0.5em;
  white-space: pre-wrap;
}
.userPanelTicketsTicketResponseFromBIT {
  margin-left: 0;
  margin-right: 2em;
  border-color: #9ad;
}
.ticketResponseFromBITUnread {
  background: #bbccf8; background: linear-gradient(#d8e4fa, #c3d3fa);
  box-shadow: 0 0 0.1em 0.1em #005da3; outline: none;
}
.bgRead {
  background: #bbccf8; background: linear-gradient(#f1f4ff, #e3eaff);
}


.notification {
  display: inline-block;
  border: solid 1px #e8e8e8;
  border-radius: 1em;
  padding: 0.15em 0.7em;
  background: #bd2326;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
}
.notificationBottomRight {
  position: absolute;
  bottom: 0.3em;
  right: 0;
}
.notificationContainer { /* puts notifications inside of intended parent divs */
  position: relative;
}

.notificationsUnreadBoxShadow { box-shadow: 0 0 0.1em 0.1em #005da3; outline: none; }






/* ======================================================================================================= */
/* GCSS {fedcomp: #ffbd29/#172432}*/
.bgGcssSamLightGradient { background: linear-gradient(#ffccb5,#eea565); }
.hoverGcssSamLighterGradient:hover { background: linear-gradient(#ffd7c0,#f8b070); }
.bgGcssSamDarkGradient { background: linear-gradient(#e84,#b51); }
.fontGcssSam { color: #f8a050; }

.bgGcssGovWinLightGradient { background: linear-gradient(#b4e0ff,#70c1fb); }
.hoverGcssGovWinLighterGradient:hover { background: linear-gradient(#bfebff,#7bccff); }
.bgGcssGovWinDarkGradient { background: linear-gradient(#1089ba,#0069aa); }
.fontGcssGovWin { color: #70c9fa; }

.bgGcssFedCompLightGradient { background: linear-gradient(#ffd849,#ffbd29); }
.hoverGcssFedCompLighterGradient:hover { background: linear-gradient(#ffe354,#ffc834); }
.bgGcssFedCompLighterGradient { background: linear-gradient(#fff6d0,#fff399); }
.bgGcssFedCompDarkGradient { background: linear-gradient(#efaf09,#bf8a09); }
.fontGcssFedCompDarkBlue { color: #172432; }
.fontGcssFedComp { color: #ffbd29; }
.fontGcssFedCompDark { color: #af7a00; }

.bgGcssCexaiLightGradient { background: linear-gradient(#cec,#beb); }
.hoverGcssCexaiLighterGradient:hover { background: linear-gradient(#aea,#7d7); }
.bgGcssCexaiDarkGradient { background: linear-gradient(#00a35d, #185a30); }
.fontGcssCexai { color: #4e4; }






/* ======================================================================================================= */
/* Inputs */

.ceButton { padding: 0.25em 1em 0.32em 1em; border-style: solid; border-width: 2px; border-radius: 0.4em; }

.ceButtonBlue { background: #005da3; border-color: #16b #246 #246 #16b; color: #fff; }
.ceButtonBlue:hover { background: #16b; border-color: #27c #357 #357 #27c; }
.ceButtonBlue:active { background: #27c; border-color: #357 #27c #27c #357; outline: none; }

.ceButtonBlueDisabled { background: #666f88; border-color: #16b #246 #246 #16b; color: #f0f0f0; }

.ceButtonGray { background: #d5d5d5; border-color: #ddd #888 #888 #ddd; color: #333; }
.ceButtonGray:hover { background: #e5e5e5; border-color: #eee #999 #999 #eee; }
.ceButtonGray:active { background: #f5f5f5; border-color: #999 #eee #eee #999; outline: none; }

.ceButtonRed { background: #bd2326; border-color: #c33 #622 #622 #c33; color: #fff; }
.ceButtonRed:hover { background: #cd3336; border-color: #d44 #733 #733 #d44; color: #fff; }
.ceButtonRed:active { background: #d44; border-color: #733 #d44 #d44 #733; color: #fff; outline: none; }

.ceButtonCaptureRed { background: #885055; background: linear-gradient(#996066, #885055); border-color: #a66 #744 #744 #a66; color: #fff; }
.ceButtonCaptureRed:hover { background: #996066; background: linear-gradient(#aa7077, #996066); border-color: #b77 #855 #855 #b77; }
.ceButtonCaptureRed:active { background: #aa7077; border-color: #855 #b77 #b77 #855; outline: none; }

.ceButtonGreen { background: #00a35d; border-color: #0a6 #073 #073 #0a6; color: #fff; }
.ceButtonGreen:hover { background: #1b7; border-color: #1b7 #184 #184 #1b7; color: #fff; }
.ceButtonGreen:active { background: #2c8; border-color: #2c8 #295 #295 #2c8; color: #fff; outline: none; }

.ceButtonAdd { padding: 0.13em 1.05em; background: #f0f0f0; background: linear-gradient(#f2f2f2, #e3e3e3); border-style: solid; border-width: 1px; border-color: #999; border-radius: 0.65em; }
.ceButtonAdd:hover { background: #f4f4f4; background: linear-gradient(#f5f5f5, #e6e6e6); border-color: #aaa; }
.ceButtonAdd:active { background: #f5f5f5; outline: none; }

.generateTemplateButtonBgBorderAndHover { background: #869; background: linear-gradient(#97a, #758); border: solid 1px; border-color: #dbe #647 #647 #dbe; border-radius: 0.5em; }
.generateTemplateButtonBgBorderAndHover:hover { background: #98a; background: linear-gradient(#a8b, #869); }






.hoverDeleteButtonX:hover {
  color: #bd2326;
  font-weight: bold;
}

.deleteMenuLauncherHover {
  border: none;
  background-color: transparent;
  color: #666;
}
.deleteMenuLauncherHover:hover {
  border: solid 1px #ddd;
  background-color: #e8e8e8; background: linear-gradient(#f2f2f2, #e3e3e3);
  color: #333;
  font-weight: bold;
}







/* ======================================================================================================= */
/* General */
.helpIcon {
  border: solid 1px #9ac;
  background-color: #fefeff;
  color: #9ac;
}
.helpIcon:hover { color: #f5f6ff; }






.hovertext {
  position: relative;
  border-bottom: 1px dotted black;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 5px;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}




.animateDisappearReappear1sInfinite {
  animation: animationKeyframesDisappear 1s infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes animationKeyframesDisappear {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes rotate360 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




.animationFadeIn {
  animation-name: keyframesAnimationFadeIn;
  animation-duration: 2s;
}

@keyframes keyframesAnimationFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.animationSlideLeft {
  animation-name: keyframesAnimationSlideLeft;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}

@keyframes keyframesAnimationSlideLeft {
  from { opacity: 0; transform: translate3d(10%, 0, 0); }
  to { opacity: 1; transform: translate3d(0, 0, 0); }
}
